"use client"
import { CategorySectionType, SortOrder, useCategoriesForFooterQuery } from '@/graphql/generated/schema'
import Link from 'next/link'
import React from 'react'

export default function CategorySection() {
    const {data = {categories:[]}} = useCategoriesForFooterQuery({
        variables:{
            where:{
postSectionType:{
    equals:CategorySectionType.IsGridSection
}
            }
            ,
           orderBy:{
            subcategory:{
                _count:SortOrder.Desc
            }
           }
        
        }
    })
    const {data:Others ={categories:[]}} = useCategoriesForFooterQuery({
        variables:{
            where:{
                postSectionType:{
                    not:{
                        equals:CategorySectionType.IsGridSection
                    }
                },
               
                            },

           orderBy:{
          subcategory:{
            _count:SortOrder.Desc
          }

           }
           ,

        }
    })
  const  categories = [...data?.categories , ...Others?.categories] ??[]

  const categoryWithoutSubcat = categories.filter((elem)=> elem.subcategory.length===0)
  const categoryWithSubcat = categories.filter((elem)=> elem.subcategory.length>0)
  return (
    <div>
        <div className="grid xs:grid-cols-2 sm:max-xl:grid-cols-2 text-base  xl:grid-cols-4  place-items-stretch mb-5">
{
    categoryWithoutSubcat.map((category)=> (
<p className='text-xl py-5 border-b pb-2 font-medium'>
<Link href={`/${category.slug}`}>
{category.category}
</Link>
</p>
    ))
    
}
    </div>

        {
            categoryWithSubcat.map((category)=> (
<div className=' flex flex-col justify-center'>
  
<p className='text-xl pb-2 font-medium'>
<Link href={`/${category.slug}`}>
{category.category}
</Link>
</p>

  
<div className="grid xs:grid-cols-2 sm:max-xl:grid-cols-3 text-base  xl:grid-cols-5 gap-x-2 place-items-stretch border-b mb-5">
{
    category.subcategory.map((subcat)=>(
        <span className=' my-2'><Link href={`/${category.slug}/${subcat.slug}`}>
        
            {subcat.subCategoryName}
        </Link>

        </span>
))
}
</div>

</div>
            ))
        }

</div>
  )
}
