import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/app/production/thehawk-frontend/node_modules/.pnpm/@ant-design+nextjs-registry@1.0.1_@ant-design+cssinjs@1.21.1_react-dom@18.2.0_react@18.2.0__r_bqjw7m5nykxwtkaxvofw3v44je/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/root/app/production/thehawk-frontend/node_modules/.pnpm/@apollo+client-react-streaming@0.11.3_@apollo+client@3.11.8_@types+react@18.2.37_graphql-ws@5_o5nl4abnramb4kicy5osopm7ha/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.79.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.79.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.79.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.79.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.79.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/app/production/thehawk-frontend/src/app/SiteHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/app/production/thehawk-frontend/src/components/Footer/CategorySection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/app/production/thehawk-frontend/src/components/Header/MainNav2Logged.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/app/production/thehawk-frontend/src/components/SocialsList/SocialsList.tsx");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/src/images/logo-light.png");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/src/images/logo.png");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/src/images/thehawk_logo.png");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloWrapper"] */ "/root/app/production/thehawk-frontend/src/providers/ApolloProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/app/production/thehawk-frontend/src/providers/AuthSessionProvider.tsx");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/src/providers/ProgressBar.tsx");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/src/styles/index.scss");
